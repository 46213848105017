import { Range } from "react-range";
import { nRo } from "../utils";
import Loading from "./Loading";

const Simple = ({
  data,
  selected,
  setSelected,
  onChangeLoan,
  resultCalculate,
  loadingCalculate,
}) => {
  const calculateRataLunara = () => {
    if (parseInt(resultCalculate.plataMaxima === resultCalculate.plataMinima)) {
      return `${nRo(resultCalculate?.plataMaxima || 0, 2)}`;
    }
    return `${nRo(resultCalculate?.plataMaxima || 0, 2)} -
      ${nRo(resultCalculate?.plataMinima || 0, 2)}`;
  };
  return (
    <>
      <div className="row no-gap">
        <div
          className="col-12 col-md-6  py-100 px-40"
          style={{
            minHeight: 300,
          }}
        >
          <h2>De cati bani ai nevoie?</h2>
          <div className="row mb-2">
            <div className="col-4 text-left">
              {nRo(selected?.sumaMinima)} lei
            </div>
            <div className="col-4 text-center">
              <span className="value">
                <strong>{nRo(selected?.suma_selectata)} lei</strong>
              </span>
            </div>
            <div className="col-4 text-right">
              {nRo(selected?.sumaMaxima)} lei
            </div>
          </div>
          <Range
            step={selected.step}
            min={selected.sumaMinima}
            max={selected.sumaMaxima}
            values={[selected.suma_selectata]}
            onChange={(values) => {
              setSelected((prev) => ({ ...prev, suma_selectata: values }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "6px",
                  width: "100%",
                  backgroundColor: "#ccc",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "25px",
                  width: "50px",
                  borderRadius: 5,
                  backgroundColor: "#f3a142",
                }}
              />
            )}
          />

          <div className="custom-controls-stacked mt-24">
            <h2>Ce tip de imprumut doresti?</h2>
            {data?.map(({ id, tipImprumut }) => (
              <div key={id}>
                <label className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    value={`${id}`}
                    checked={selected.id === `${id}`}
                    onChange={(ev) => onChangeLoan(ev.target.value)}
                  />
                  <span className="custom-control-indicator"></span>
                  <span className="custom-control-description">
                    {tipImprumut}
                  </span>
                </label>
              </div>
            ))}
          </div>

          <div className="mt-24">
            <h2>Pe ce perioada vrei imprumutul?</h2>
            <div className="row mb-2">
              <div className="col-4 text-left">
                {selected?.numarRateMin} luni
              </div>
              <div className="col-4 text-center">
                <span className="value">
                  <strong>{selected?.perioada_imprumut} luni</strong>
                </span>
              </div>
              <div className="col-4 text-right">
                {selected?.numarRateMax} luni
              </div>
            </div>
            <Range
              step={1}
              min={selected.numarRateMin}
              max={selected.numarRateMax}
              values={[selected.perioada_imprumut]}
              onChange={(values) => {
                setSelected((prev) => ({
                  ...prev,
                  perioada_imprumut: values[0],
                }));
              }}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "100%",
                    backgroundColor: "#ccc",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "25px",
                    width: "50px",
                    borderRadius: 5,
                    backgroundColor: "#f3a142",
                  }}
                />
              )}
            />
          </div>
        </div>

        <div
          className="col-12 col-md-6  py-100 px-40 text-center section-inverse"
          style={{ backgroundColor: "#f3a142" }}
        >
          <h1>Valoarea imprumutata</h1>
          <h3>
            {loadingCalculate ? (
              <Loading withSection={false} />
            ) : (
              `${nRo(resultCalculate?.totalSold || 0, 2)} lei`
            )}
          </h3>
          <br />
          <h1>Rata lunara:</h1>

          {loadingCalculate ? (
            <Loading withSection={false} />
          ) : (
            <>
              <h3>{calculateRataLunara()} lei</h3>

              {/* <button
                data-toggle="modal"
                data-target="#modal-simulator-imprumut"
                className="btn btn-outline btn-dark mt-10"
              >
                Simulare detaliata
              </button> */}
            </>
          )}
        </div>

        <div
          className="col-lg-12 col-md-12 section-inverse text-center"
          style={{ backgroundColor: "#CD7C0F" }}
        >
          {!loadingCalculate ? (
            <ul className="list-inline my-2">
              <li className="list-inline-item">
                <strong>Dobanda</strong>:{" "}
                <span>{nRo(resultCalculate?.dobanda || 0)}</span> % p.a.
              </li>
              <li className="list-inline-item">
                <strong>DAE</strong>:{" "}
                <span>{nRo(resultCalculate?.dae || 0, 2)}</span> % p.a.
              </li>
              <li className="list-inline-item">
                <strong>Valoarea totala de rambursat</strong>:{" "}
                <span>{nRo(resultCalculate?.totalPlata || 0, 2)} lei</span>
              </li>
            </ul>
          ) : (
            <Loading withSection={false} />
          )}
        </div>
      </div>
    </>
  );
};

export default Simple;
