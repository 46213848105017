import React, { useCallback } from "react";

import Loading from "./components/Loading";
import { useFetch } from "./useFetch";
import CalculatorSimple from "./components/CalculatorSimple";
import debounce from "debounce";
// import SimulareDetaliat from "./components/SimulareDetaliat";
const App = () => {
  const { loading, data, loadingCalculate, onCalculate, resultCalculate } =
    useFetch();
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    if (data && data.length > 0) {
      setSelected({
        ...data[0],
        suma_selectata: Math.floor(data[0].sumaMaxima),
        tip_imprumut: `${data[0].id}`,
        perioada_imprumut: `${data[0].numarRateMax}`,
        id: data[0].id,
      }); // default first
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (selected) {
      verify(selected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const deb = debounce((s) => {
    onCalculate({
      dobanda: s.dobanda,
      suma: s.suma_selectata,
      numarRate: s.perioada_imprumut,
    });
  }, 500);

  // eslint-disable-next-line
  const verify = useCallback(deb, []);

  const onChangeLoan = (id) => {
    const sel = data.filter((d) => `${d.id}` === id);

    if (sel.length) {
      setSelected({
        ...sel[0],
        suma_selectata: Math.floor(sel[0].sumaMaxima),
        tip_imprumut: `${sel[0].id}`,
        perioada_imprumut: `${sel[0].numarRateMax}`,
      });
    }
  };

  if (loading || !selected) {
    return <Loading withSection={true} />;
  }

  return (
    <>
      <CalculatorSimple
        selected={selected}
        setSelected={setSelected}
        data={data}
        onChangeLoan={onChangeLoan}
        resultCalculate={resultCalculate}
        loadingCalculate={loadingCalculate}
      />
      {/* {resultCalculate && resultCalculate.grafic ? (
        <SimulareDetaliat grafic={resultCalculate.grafic} />
      ) : null} */}
    </>
  );
};

export default App;
