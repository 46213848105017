import React from "react";
import axios from "axios";
import {
  URL_CALCULATOR_RATA,
  URL_CALCULATOR_RATA_CALCULATE,
} from "./constants";

export const useFetch = () => {
  const [loading, setLoading] = React.useState(true);
  const [loadingCalculate, setLoadingCalculate] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [resultCalculate, setResultCalculate] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(URL_CALCULATOR_RATA)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onCalculate = React.useCallback(({ numarRate, suma, dobanda }) => {
    setLoadingCalculate(true);

    axios
      .get(URL_CALCULATOR_RATA_CALCULATE, {
        params: {
          numarRate,
          suma: +suma,
          dobanda,
        },
      })
      .then((response) => {
        console.log("response calculate", response.data);
        setResultCalculate(response.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoadingCalculate(false);
      });
  }, []);

  return {
    loading,
    data,
    error,
    onCalculate,
    loadingCalculate,
    resultCalculate,
  };
};
