// we can use international here later
export const nRo = (number, point = false) => {
  if (typeof number === "undefined") return 0;

  if (point) {
    return parseFloat(number)
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
