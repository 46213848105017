import ReactLoading from "react-loading";

/**
 *
 * @param {*} color
 * @param {*} withSection
 * @returns
 */
const Loading = ({ color = "#2980b9", withSection = false }) => {
  if (!withSection) {
    return <ReactLoading type="bubbles" color={color} height={46} width={46} />;
  }

  return (
    <section className="bg--secondary" name="calculator-rata">
      <div className="container">
        <div className="cta cta-1 cta--horizontal boxed boxed--border m-0 p-0">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center p-2 my-5">
              <span className="h2">Se incarca calculatorul de rate</span>
              <div className="row justify-content-center">
                <div className="col-xs-2">
                  <ReactLoading type="spinningBubbles" color={color} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Loading;
